.SummaryPlanContainer {
    margin-top: 20px;
}

.ToggleButtons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.ToggleButton {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0; 
    color: #333; 
    margin: 0 5px;
    transition: background-color 0.3s, color 0.3s;
}

.ToggleButton.active {
    background-color: #4CAF50; 
    color: white; 
}

.ToggleButton:hover {
    background-color: #45a049; /* Darker shade for hover state */
}

.ToggleButtonWrapper {
    position: relative;
    display: inline-block;
    margin: 0 5px;
}

.ToggleButtonOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 5px;
    visibility: hidden;
}

.ToggleButtonOverlay.loading {
    visibility: visible; /* Show only when loading */
}

.Content {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff; 
    color: #333; 
}

.SummarySection.active, .PlanSection.active {
    display: block;
}

.formatted-text-container {
    border-radius: 10px;
    max-width: 600px;
    font-family: 'Handlee', serif;
    color: #333;
    text-align: left;
  }

  .UserBubble .formatted-text-container {
      text-align: right;
  }

  .UserBubble .ResponseMessage p{
      color: #d0def5;
  }

  .formatted-text-container h2 {
    font-size: 22px;
    color: #333;
  }

  .formatted-text-container ul {
      margin-top: 10px;
      list-style-type: disc;
  }

  .formatted-text-container ul ul {
      padding-left: 20px;
      list-style-type: circle; /* Smaller bullets for nested items */
  }

  .formatted-text-container li {
      margin-bottom: 8px; /* Adds spacing between list items */
      line-height: 1.6;
      text-align: left;
      margin-left: 45px;
  }

  .formatted-text-container p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
    line-height: 1.6;
  }

  /* Bold text */
  .formatted-text-container strong {
    color: #222;
  }

.dark-mode .ToggleButton {
    background-color: #333; 
    color: #ddd; 
}

.dark-mode .ToggleButton.active {
    background-color: #4CAF50; 
    color: white; 
}

.dark-mode .ToggleButton:hover {
    background-color: #45a049; 
}

.dark-mode .Content {
    background-color: #222; /* Dark mode background for content area */
    color: #ddd; /* Dark mode text color for content area */
}