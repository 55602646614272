/* Reset margins and paddings for a clean layout */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of the viewport */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #333;
  color: #333;
}

.TitleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #014047;
  color: white;
  font-size: 24px;
}

.Menu {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
}

.HamburgerMenu {
  cursor: pointer;
  z-index: 1000;
}

.MenuItemsActive,
.MenuItems {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 5px 0;
}

.MenuItemsActive {
  display: block;
  animation: slideDown 0.3s ease forwards;
}

@keyframes slideDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.MenuItemsActive button,
.MenuItemsActive select,
.MenuItemsActive .MenuButton {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
  color: #333;
  font-size: 16px;
}

.MenuItemsActive button:last-child,
.MenuItemsActive select:last-child,
.MenuItemsActive .MenuButton:last-child {
  border-bottom: none;
}

.MenuItemsActive button:hover,
.MenuItemsActive select:hover,
.MenuItemsActive .MenuButton:hover{
  background-color: #f6f6f6;
}

.MainContent {
  display: flex;
  flex: 1;
  height: calc(100vh - 60px);
  overflow: hidden;
}

.ResponsePane {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 0;
}

.PersonalitySelector {
    margin: 10px;
}

.PersonalitySelector label {
    margin-right: 8px;
    font-weight: bold;
}

.PersonalitySelector select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    color: #333;
}

/* Dark Mode Styles */
.dark-mode .App {
  background-color: #333;
  color: #333;
}

.dark-mode .MenuItemsActive {
  background-color: transparent;
}

.dark-mode .MenuItemsActive button,
.dark-mode .MenuItemsActive select,
.dark-mode .MenuItemsActive .MenuButton {
  background: #444;
  color: #ddd;
  border-color: #555;
}

.dark-mode .MenuItemsActive button:hover,
.dark-mode .MenuItemsActive select:hover,
.dark-mode .MenuItemsActive .MenuButton:hover {
  background-color: #555;
}

.dark-mode .TitleBar {
  background-color: #222;
  color: #fff;
}

.dark-mode .PersonalitySelector label {
  color: #f0f0f0;
}

.dark-mode .PersonalitySelector select {
  background-color: #444;
  color: #ddd;
  border-color: #555;
}

.dark-mode .PersonalitySelector select:hover {
  background-color: #555;
}

.light-mode .App {
  background-color: #f0f0f0
}

/* Add responsive behavior */
@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .ResponsePane {
    height: 50vh;
  }

  .HamburgerMenu {
    display: flex;
  }

  .MenuItems {
    display: none;
  }

  .MenuItemsActive {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}