.ChartContainer {
    display: flex; 
    flex-wrap: wrap;
    justify-content: start; 
    align-items: flex-start;
    gap: 20px; 
    width: 100%;
}

/* Styles for each individual chart wrapper */
.ChartWrapper {
    flex: 1; 
    flex-basis: 300px;
    max-width: 600px;
}

.recharts-wrapper {
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}

.recharts-pie {
    stroke-linejoin: round; /* Smooths out the edges of the pie pieces */
}

.recharts-sector {
    stroke: #fff; /* Adds a white border between pie pieces */
    stroke-width: 2px;
}

.recharts-legend-wrapper {
    padding: 10px 0;
}

.recharts-default-tooltip {
    border-radius: 5px; /* Rounds the corners of the tooltip */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: none; /* Removes default border */
    font-size: 14px;
}

.recharts-text {
    fill: #333; /* Dark text color for better contrast */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.recharts-legend-item {
    font-size: 14px;
    margin-bottom: 4px;
}

.recharts-legend-item-text {
    color: #555; /* Sets a neutral color for legend text */
}

.recharts-tooltip-item {
    font-size: 14px;
    padding: 5px 10px;
}

.recharts-tooltip-label {
    font-weight: bold;
    color: #333;
}

.recharts-label {
    font-size: 12px;
    fill: #666; /* Sets a lighter color for labels */
}

.emotion-chart .recharts-wrapper {
    background-color: #f7f7f7; /* A different background for the emotion chart */
}

.custom-tooltip {
    background-color: #fff; /* For dark mode, you might want a dark background */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    opacity: 1; /* Adjust opacity as needed */
}

/* For dark mode styling */
.dark-mode .custom-tooltip {
    background-color: #333;
    color: #ddd;
}
