.signInScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--background-color);
    color: var(--text-color);
    transition: background 0.3s ease, color 0.3s ease;
}

.signInScreen.dark {
    --background-color: #121212;
    --text-color: #000000;
}

.signInScreen.light {
    --background-color: #f0f2f5;
    --text-color: #000000;
}

.signInCard {
    padding: 2rem;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 400px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.signInCard h2 {
    margin-bottom: 1rem;
}

input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

button.signInButton, button.registerButton {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
}

button.signInButton {
    background-color: #007bff;
}

button.registerButton {
    background-color: #28a745;
}

.error {
    color: #ff3860;
    margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .signInCard {
        padding: 1.5rem;
        width: 90%;
    }
}
