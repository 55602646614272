.JourneyHeader {
    font-size: 24px;
    color: #4CAF50;
    text-align: center;
    font-weight: normal;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 10px;
    border-bottom: 2px solid #eee;
    width: 80%;
    margin: 20px auto;
}

.EntryCard {
    background: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.EntryCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.EntryHeader {
    font-weight: bold;
    margin-bottom: 8px;
}

.EntryContent {
    margin-bottom: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 600px;
}

.EntryMetaData {
    font-size: 0.85em;
    color: #555;
}

.EntryList {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

.ViewDetailsButton:hover {
    background: #026d79;
}

.ViewDetailsButton {
    background: #014047;
    color: white;
    padding: 8px;
    border: none;
    cursor: pointer;
}

.TabsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.Tab {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-bottom: none;
    margin-right: -1px;
}

.Tab:hover {
    background-color: #026d79;
}

.TabActive {
    background-color: #014047;
    color: white;
    border-color: white;
}

.TabContent {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 0 5px 5px 5px;
}

.MinimizeButton {
    background-color: #014047;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    display: inline-block;
}

.MinimizeButton:hover {
    background-color: #026d79;
}

.DatePickerContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

input[type=date] {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
}

input[type=date]:hover {
    background-color: #f7f7f7;
}

.GenerateButtonWrapper {
    position: relative;
    display: inline-block;
}

.GenerateButtonOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 5px;
}

.GenerateButtonOverlay .loading {
    visibility: visible;
}

.GenerateButton {
    background-color: #014047;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 10px;
    width: 100%;
}

.GenerateButton:hover {
    background-color: #026d79;
}

.GenerateButton:disabled {
    background-color: #999;
    cursor: not-allowed;
}

.Loader {
    color: #014047;
}

.CalendarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
}

.react-calendar {
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    color: black;
}

.has-entry {
    background-color: #4CAF50 !important;
    color: white !important;
    border-radius: 50%;
}

.dark-mode .EntryCard {
    background: #333;
    color: white;
    box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.1);
}

.dark-mode .EntryCard:hover {
    box-shadow: 0 8px 16px 0 rgba(255,255,255,0.2);
}

.dark-mode .EntryContent .EntryMetaData div {
    color: white;
}

.dark-mode .EntryHeader {
    color: #c7c9ed;
}

.dark-mode .EntryMetaData {
    color: #aaa;
}

.dark-mode .EntryList {
    background-color: #333;
    color: white;
}

.dark-mode .ViewDetailsButton {
    background-color: #555;
    color: #ddd;
}

.dark-mode .Journey {
    background-color: #333;
    color: #f0f0f0;
}

.dark-mode .JourneyHeader {
    color: #c7c9ed;
}

.dark-mode .Tab {
    background-color: #333;
    color: #ddd;
}

.dark-mode .TabActive {
    background-color: #444;
    color: #4CAF50;
}

.dark-mode .TabContent {
    background-color: #444;
    color: #ddd;
}

.dark-mode .Tab:hover {
    background-color: #3b3b3b;
}

.dark-mode .MinimizeButton {
    background-color: #555;
}

.dark-mode .GenerateButton {
    background-color: #555;
}

.dark-mode .DatePicker {
    background-color: #444;
    color: #ddd;
    border-color: #555;
}

.dark-mode input[type=date] {
    background-color: #333;
    color: #ddd;
    border-color: #555;
}

.dark-mode input[type=date]:hover {
    background-color: #3b3b3b;
}

.dark-mode .Loader {
    color: #c7c9ed;
}

.dark-mode .react-calendar {
    background-color: #222;
    color: white;
    border: 1px solid #555;
}

.dark-mode .has-entry {
    background-color: #4CAF50 !important;
    color: white !important;
}

.dark-mode .CalendarContainer {
    background-color: #222;
    color: white;
}
