.image-generator-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Handlee', serif;
}

.image-generator-container h2 {
    text-align: center;
    color: #014047;
    margin-bottom: 20px;
}

.input-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.input-section textarea,
.input-section input[type="text"] { /* Added styling for the name input field */
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Handlee', serif;
    font-size: 16px;
    resize: vertical;
}

.input-section select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Handlee', serif;
    font-size: 16px;
    background-color: white;
    cursor: pointer;
}

.button-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.button-section button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #014047;
    color: white;
    font-family: 'Handlee', serif;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-section button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.button-section button:hover:not(:disabled) {
    background-color: #1cc6d9;
}

.error-message {
    color: #ff4d4d;
    text-align: center;
    margin-bottom: 20px;
}

.image-preview {
    text-align: center;
}

.image-preview img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Dark Mode Styles */
.dark-mode .image-generator-container {
    background-color: #1f2324;
    color: #ddd;
}

.dark-mode .image-generator-container h2 {
    color: #1cc6d9;
}

.dark-mode .input-section textarea,
.dark-mode .input-section input[type="text"] { /* Added dark mode styling for the name input field */
    background-color: #2d3334;
    color: #ddd;
    border-color: #555;
}

.dark-mode .input-section select {
    background-color: #2d3334;
    color: #ddd;
    border-color: #555;
}

.dark-mode .button-section button {
    background-color: #1cc6d9;
    color: #1f2324;
}

.dark-mode .button-section button:hover:not(:disabled) {
    background-color: #14a8b8;
}

.dark-mode .error-message {
    color: #ff6b6b;
}

.dark-mode .image-preview img {
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}