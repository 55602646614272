@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');

/* Journal Entry Text Area Styling */
.JournalInput {
    width: 100%;
    flex-grow: 1;
    padding: 12px 15px 15px 15px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    background-color: #cad3e0;
    resize: none;
    margin-bottom: 10px;
    min-height: 60vh;
    font-family: 'Handlee', serif;

    /* Adjust line height to match background lines */
    line-height: 36px;

    /* Lined paper effect */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
    background-size: 100% 36px;

    /* Ensures background moves with the text */
    background-attachment: local;
}


.AnalyzeButton {
    align-self: flex-start; /* Aligns button to the left */
    padding: 10px 15px;
    border-radius: 20px;
    background-color: #014047;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    margin-top: auto; /* Pushes button to bottom */
}

.ButtonContainer {
    display: flex;
    justify-content: flex-start; /* Aligns buttons to the left */
    gap: 20px; /* Adds space between buttons */
    margin-top: 10px; /* Adds some spacing from the textarea */
}

.JournalPane {
    flex: 1.2;
    padding: 10px 0 20px 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column; /* Stack journal input and button */
}

.dark-mode .JournalPane, .dark-mode .ResponsePane {
    background-color: #444;
    color: #ddd;
}

.dark-mode .JournalInput {
    background-color: #222;
    color: white;
    background-image: linear-gradient(to bottom, rgba(252, 252, 252, 20%) 1px, transparent 1px);
}

.dark-mode .AnalyzeButton {
    background-color: #1cc6d9;
    color: black;
}

@media (max-width: 768px) {
    .JournalPane {
        height: 50vh;
    }
}