@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');

.Responses {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100vh - 50px); /* Adjust based on input height */
    overflow-y: auto;
    padding-bottom: 60px; /* Matches input height */
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.Responses::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Responses {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.ResponseBubble {
    border-radius: 20px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s;
}

.ResponseBubble:hover {
    background-color: #f0f0f0;
}

.ResponseContent {
    flex-grow: 1;
}

.ResponseLoader {
    display: flex;
    align-items: flex-end;
}

.ResponseMessage {
    border-radius: 20px;
    padding: 5px 10px 5px 10px;
    max-width: calc(100% - 40px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    color: #333;
}

.ExpandCollapseIcon {
    text-align: center;
    font-size: 12px;
    margin-right: 40px;
}

/* Quilly's messages */
.QuillyBubble .ResponseMessage {
    background-color: #d3dadb;
    border-bottom-left-radius: 0;
}

.QuillyBubble {
    flex-direction: row;
}

.QuillyBubble .QuillyAvatar {
    order: -1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
}

/* User's messages */
.UserBubble .ResponseMessage {
    background-color: #014047;
    color: white;
    border-bottom-right-radius: 0;
}
.UserBubble {
    flex-direction: row-reverse;
    margin-left: 45px;
}

.UserAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: -35px;
}

.ResponseText {
    color: #333;
    margin-top: 5px;
}

.ResponseUser {
    font-weight: bold;
}

.QuillyAvatar {
    order: -1;
    width: 40px; 
    height: 40px; 
    border-radius: 50%; 
    object-fit: cover;
    margin-left: 5px;
}

.ChatInputContainer {
    width: 100%;
    max-width: 100%;
    position: relative;
    padding: 10px;
    bottom: 0;
    display: flex;
    justify-content: center;
}

.ChatInputForm {
    display: flex;
    flex-grow: 1;
}

.ChatInput {
    flex-grow: 1;
    padding: 10px 15px;
    border-radius: 20px;
    border: 2px solid #ccc;
    outline: none;
    width: 80%;
    font-family: 'Handlee', serif;
    font-size: 16px;
}

.ChatSubmitButton {
    padding: 0 15px;
    border-radius: 20px;
    background-color: #014047;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
}

.formatted-text-container {
    border-radius: 10px;
    max-width: 600px;
    font-family: 'Handlee', serif;
    color: #333;
    text-align: left;
  }

  .UserBubble .formatted-text-container {
      text-align: right;
  }

  .UserBubble .ResponseMessage p{
      color: #d0def5;
  }

  .formatted-text-container h2 {
    font-size: 22px;
    color: #333;
  }

  .formatted-text-container ul {
      margin-top: 10px;
      list-style-type: disc;
  }

  .formatted-text-container ul ul {
      padding-left: 20px;
      list-style-type: circle; /* Smaller bullets for nested items */
  }

  .formatted-text-container li {
      margin-bottom: 8px; /* Adds spacing between list items */
      line-height: 1.6;
      text-align: left;
      margin-left: 45px;
  }

  .formatted-text-container p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
    line-height: 1.6;
  }

  /* Bold text */
  .formatted-text-container strong {
    color: #222;
  }

/* Dark mode styles for chat input */
.dark-mode .ChatInput {
    background: #1f2324;
    color: #ddd;
    border-color: #555;
}
.dark-mode .ChatSubmitButton {
    background-color: #1cc6d9;
    color: black;
}

.dark-mode .ResponseBubble:hover {
    background-color: #555;
}

.dark-mode .ResponseMessage {
    color: #c7c9ed;
}

.dark-mode .QuillyBubble .ResponseMessage {
    background-color: #1f2324;
}

.dark-mode .UserBubble .ResponseMessage p{
    color: #d0def5;
}

.dark-mode .formatted-text-container {
    color: #ddd; /* Light text */
  }

  .dark-mode .formatted-text-container h2 {
    color: #f1c40f; /* Warm gold for title */
  }

  .dark-mode .formatted-text-container ul {
    list-style-type: square; /* Slightly different styling */
  }

  .dark-mode .formatted-text-container li {
    color: #bbb; /* Softer white for list items */
  }

  .dark-mode .formatted-text-container p {
    color: #ccc; /* Light text */
  }

  .dark-mode .formatted-text-container strong {
    color: #f8d354; /* Highlight bold text in warm yellow */
  }
