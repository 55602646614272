.MyGoals {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.MyGoals h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.GoalsList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.Goal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Goal h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.Goal p {
    font-size: 16px;
    color: #666;
}

.ChatContainer {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ChatContainer h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.ChatContainer form {
    display: flex;
    margin-top: 10px;
}

.ChatContainer input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.ChatContainer button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #014047;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
}

.ChatContainer button:hover {
    background-color: #015760;
}

/* Dark mode styles */
.dark-mode .MyGoals {
    background-color: #333;
}

.dark-mode .MyGoals h2 {
    color: #fff;
}

.dark-mode .Goal {
    background-color: #444;
}

.dark-mode .Goal h3 {
    color: #fff;
}

.dark-mode .Goal p {
    color: #ccc;
}

.dark-mode .ChatContainer {
    background-color: #444;
}

.dark-mode .ChatContainer h3 {
    color: #fff;
}

.dark-mode .ChatContainer input {
    background-color: #333;
    color: #fff;
    border-color: #555;
}

.dark-mode .ChatContainer button {
    background-color: #555;
}

.dark-mode .ChatContainer button:hover {
    background-color: #666;
}